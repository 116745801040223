<template>
  <div>
    <h2>{{ "Ihre Rechnung" | translate }}</h2>
    <div class="row" v-if="openOrders">
        <div class="col-lg-12" v-for="item in order" :key="item.orderId">
          <div class="panel p-2">
            <div class="panel t3 p-2 mb-2">
              {{ "Tisch" | translate }}:
              <strong>{{ table(item.tableNumber) }}</strong>
              <br />
              {{ "Datum" | translate }}:
              <strong>{{ time(item.firstOrder) }}</strong>
              <br />
              {{ "Bestell Nr" | translate }}:
              <strong>{{ item.orderId }}</strong>
              <br />
              {{ "Offener Betrag" | translate }}:
              <strong>{{ item.total | currency(cSymb) }}</strong>
              <br />
            </div>

            <div class="panel p-2 mb-2">
              <h5 class="m-0 p-0">{{ "Positionen" | translate }}:</h5>
              <div v-for="(pos, index) in item.lines" :key="index">
                <div class="row pl-3 pt-1">
                  <div class="col-lg-12">
                    <strong>{{ pos.quantity }} x {{ pos.name }}</strong>
                    (@{{ (pos.posTotal)/pos.quantity | currency(cSymb) }})

                    <div v-if="pos.addText.length > 0" class="item-note d-inline-block" >
                      <BaseIcon name="paperclip" width="16" height="16" class="mt-0" />
                      <i>{{ "**" + pos.addText + "**" }}</i>
                    </div>

                    <br />
                    <i v-bind:class="getClass(pos.posTotal)">
                      {{ "Total" | translate }} =
                      {{ pos.posTotal | currency(cSymb) }}
                    </i>
                    <hr>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel t3 p-2 mb-1">
              <div v-if="item.total > 0" class="row mb-2">
                <div class="col-lg-6">
                  <h5 class="text-right mt-1"><strong>{{ "Betrag eingeben" | translate }}:</strong></h5>

                </div>
                <div class="col-lg-6">
                    <b-form-input
                      ref="pymtAmt0"
                      type="text"
                      style="width:54px;font-size:20px;height:38px;font-weight:bold;padding:2px"
                      class="text-right float-left"
                      v-model="payment0"
                      v-on:keyup="checkNumber(payment0,4,$event)"
                    ></b-form-input>
                    <span class="float-left mt-1 ml-1" style="font-size:20px;font-weight:bold"><strong>,</strong></span>
                    <b-form-input
                      ref="pymtAmt1"
                      type="text"
                      style="width:36px;font-size:20px;height:38px;font-weight:bold;padding:2px"
                      class="text-right text-center float-left ml-1"
                      v-model="payment1"
                      v-on:keyup="checkNumber(payment1,2,$event)"
                    ></b-form-input>
                    <span class="float-left mt-1 ml-1" style="font-size:20px;font-weight:bold"><strong>{{ cSymb }}</strong></span>
                </div>
              </div>

              <div class="row">

                <div class="col-lg-6">
                  <button class="btn btn-md btn-block btn-secondary ml-0 mb-2" type="button" @click="$router.go(-1)">
                    {{ "Abbrechen" | translate }}
                  </button>
                </div>
                <div class="col-lg-6">
                  <button v-if="item.total > 0" class="btn btn-md btn-block btn-primary ml-0 mb-2" @click="goToPay()">
                    {{ checkoutBtn | translate }}
                  </button>
                  <button v-else class="btn btn-md btn-block btn-secondary ml-0 mb-2" type="button" @click="$router.go(-1)"> 
                    {{ "Alles Bezahlt!" | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-lg-12">
          <h5 class="text-center"><strong>{{"Alles Bezahlt!" | translate}}</strong></h5>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataService from "@/services/DataService";

export default {
  name: "app-poslist",
  components: {},
  data() {
    return {
      openOrders: true,
      order: [],
      total: 0,
      posOrderId: "",
      orderId: "",
      payment0: 0,
      payment1: 0,
      finalPmnt: 0,
      checkoutBtn: "Jetzt Alles Bezahlen"
    };
  },
  created: function () {
    DataService.getOrder(this.companyData.tableNumber, this.companyData.apiKey, this.companyData.tableOrderToken).then(
      (response) => {
        if(response.data.ordersCount > 0){
          this.openOrders = true;
          this.order = response.data.orders;
          this.total = Number(response.data.orders[0].total).toFixed(2);
          this.posOrderId = response.data.orders[0].posOrderId;
          this.orderId = response.data.orders[0].orderId;
          this.finalPmnt = this.total;
          let x = this.total + '';
          this.payment0 = x.split('.')[0];
          this.payment1 = x.split('.')[1];
        }else{
          this.openOrders = false;
        }
      }
    );
  },

  computed: {
    ...mapGetters(["companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
  methods: {
    ...mapActions(["setBill"]),
    goBack() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 300);
    },
    time(value) {
      var a = new Date(value);
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min.toString().length == 1) {
        min = "0" + min;
      }
      var time = date + "/" + month + "/" + year + " " + hour + ":" + min;

      return time;
    },
    pmnt(value) {
      if (value == 1) {
        return "Kredit Karte";
      }
      if (value == 2) {
        return "Sonstige Karte";
      }
      if (value == 3) {
        return "Paypal";
      }
      if (value == 4) {
        return "Bar bei Abholung/Lieferung";
      }
      if (value == 5) {
        return "An der Kasse";
      }
    },
    table(value){
      return value.split(',')[0];
    },
    getClass(val){
      if(val>0){
        return "text-danger";
      }else if(val < 0){
        return "text-success";
      }
    },
    fixNumber(){
      this.finalPmnt= Number(this.payment0 + "." + this.payment1);
      if(this.finalPmnt > this.total || this.finalPmnt == 0){
        let x = this.total + '';
        this.payment0 = x.split('.')[0]+'';
        this.payment1 = x.split('.')[1]+'';
        this.checkoutBtn = "Jetzt Alles Bezahlen";
      }else if(this.finalPmnt == this.total){
        this.checkoutBtn = "Jetzt Alles Bezahlen";
      }else{
        this.checkoutBtn = "Jetzt Teilbetrag Bezahlen";
      }
    },
    checkNumber(t,d,v){
      const val = v.target.value.replace(/[^0-9]/g, "");
      if (val.length > d){
        this[t+''] = val.slice(0, d);
      } else{
        this[t+''] = val;
      }
      this.fixNumber();
    },
    goToPay() {
      var data = {
        PosOrderId: this.posOrderId,
        orderId: this.orderId,
        Amount: this.finalPmnt
      };
      this.setBill(data);
      this.$router.push({name: "Payment"});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>